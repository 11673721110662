import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { createProjectSlides } from '../utils'

import {
  Layout,
  TextBox,
  BannerSmall,
  BlogSlider,
  ProjectSlider,
  CallUs,
  UserExperience,
  BannerLinks,
  AppointmentIndividual
} from '../components'

import contacts from '../components/CallUs/DefaultContacts'
import boxes from '../components/BannerLinks/DefaultBox'

import Farbverlauf from '../assets/verlauf.jpg'
import SandsofTime from '../assets/sandsoftime.jpg'
import Mouse from '../assets/mouse.opt.gif'
import HandyNav from '../assets/handynavigation.jpg'
import HowIsYourDay from '../assets/splitscreens.jpg'
import BannerImage from '../assets/technologie.jpg'
import matomo_m from '../assets/matomo_m.png'
import MasterOfDesign from '../assets/bannerlinks/masterofdesign.jpg'
import Finden from '../assets/bannerlinks/finden.jpg'
import ie_grave from '../assets/internet-explorer-gravestone-overlay.png'
import anja_thumbnail from '../assets/anja-friedensdorf-interview.jpg'
import cube from '../assets/Rubixcube.png'

// import berufsreise from '../../assets/projectslider/slider.berufsreise.groß.jpg'
import morf from '../assets/projectslider/slider.morf.gross.jpg'
import ikb from '../assets/projectslider/slider.ikb.groß.jpg'
import dibk from '../assets/projectslider/slider.dibk.klein.jpg'
// import edelwatt from '../../assets/projectslider/slider.edelwatt.klein.jpg'
import werhilftwie from '../assets/projectslider/slider.werhilftwie.klein.png'
import eglo from '../assets/projectslider/slider.eglo.klein.jpg'
// import giesswein from '../../assets/projectslider/slider.giesswein.klein.jpg'
// import intercable from '../../assets/projectslider/slider.intercable.klein.jpg'
import ak from '../assets/projectslider/slider.aktirol.klein.png'
import stadtamthall from '../assets/projectslider/slider.stadtamthall.klein.png'
// import tiwag from '../../assets/projectslider/slider.tiwag.klein.jpg'
// import tyczka from '../../assets/projectslider/slider.tyczka.klein.jpg'
import voltadol from '../assets/projectslider/slider.voltadol.klein.jpg'
import wedl from '../assets/projectslider/slider.wedl.klein.jpg'
// import tq from '../../assets/projectslider/slider.tq.png'
import wko from '../assets/projectslider/slider.wko.klein.jpeg'
import klepschgroup from '../assets/projectslider/slider.klepschgroup.jpg'

const projects = createProjectSlides({
  bigImages: [
    {
      image: klepschgroup,
      text: (
        <span>
          <h2>Klepsch Gruppe</h2>
          <p>
            Das Multiportal der Klepsch Gruppe wurde dem State-of-the-Art des
            Webs angepasst und ein weiteres Portal für Zellparts wurde erstellt.{' '}
          </p>
        </span>
      ),
      link: '/projekte/klepsch-group'
    },
    {
      image: morf,
      text: (
        <span>
          <h2>MOMO - Ein Tool für alles</h2>
          <p>
            Projektverwaltung, Kundenverwaltung, Angebotslegung,
            Einsatzdisponierung, Rechnungserstellung, Mitarbeiterverwaltung,
            Aufwandserhebung - alles Bereiche die dieses hervorragende Tool
            abdeckt.
          </p>
        </span>
      ),
      link: '/projekte/morf'
    },
    {
      image: ikb,
      text: (
        <span>
          <h2>Von A wie Aufguss bis Z wie Zählerstand</h2>
          <p>
            Als kommunales Infrastruktur-Dienstleistungsunternehmen dient die
            Webseite der IKB vor allem als Informationsportal und somit als
            weiterer Service für ihre Kund:innen. Bei einer Webseite mit so vielen
            Inhalten wie auf ikb.at steht die Skalierbarkeit aus technischer
            Sicht an erster Stelle.
          </p>
        </span>
      ),
      link: '/projekte/ikb'
    }
  ],
  smallImages: [
    {
      image: dibk,
      text: (
        <span>
          <h2>Diözese Innsbruck Relaunch</h2>
          <p>
            Beim letzten kompletten Redesign 2016 realisierten wir ein
            hochkomplexes Mutliportalsystem, welches aus hunderten unabhängigen
            Internetauftritten besteht.
          </p>
        </span>
      ),
      link: '/projekte/dioezese'
    },
    {
      image: ak,
      text: (
        <span>
          <h2>Online mit Köpfchen</h2>
          <p>
            DAS Tool für die Durchführung von digitalen Workshops - inklusive
            Quiz von uns konzipiert und als Web-App umgesetzt.
          </p>
        </span>
      ),
      link: '/projekte/arbeiterkammer'
    },
    {
      image: wko,
      text: (
        <span>
          <h2>Die digitale Lernwelt</h2>
          <p>
            Mit der Lernplattform “Deine Lehre”, auf Open Source Basis und durch
            die Verwendung von Moodle und H5P, wird mit einfachen
            Lernmaterialien eine interaktive, digitale Lernwelt geschaffen.
          </p>
        </span>
      ),
      link: '/projekte/wirtschaftskammer'
    },
    {
      image: stadtamthall,
      text: (
        <span>
          <h2>Lebendiges Redesign - Stadtamthall</h2>
          <p>
            Die Bürgerthemen werden durch die neu gestaltete Website ordentlich
            aber lebendig dargestellt.
          </p>
        </span>
      ),
      link: '/projekte/stadtamthall'
    },
    {
      image: werhilftwie,
      text: (
        <span>
          <h2>Wer Hilft Wie Suchmaschine</h2>
          <p>
            Redesign der Wer Hilft Wie Suchmaschine. DIE Suchmaschine für
            Soziale Einrichtungen in Tirol. Die Adressen und Beschreibungen der
            Einrichtungen sind Kategorien und Suchbegriffen zugeordnet.
          </p>
        </span>
      ),
      link: '/projekte/werhilftwie'
    },
    {
      image: voltadol,
      text: (
        <span>
          <h2>Voltadol Portal</h2>
          <p>
            Eine Microsite mit Produktinformationen, Daten und Fakten zum
            Krankheitsbild und Tipps zur Vorsorge.
          </p>
        </span>
      ),
      link: 'https://www.voltadol.at'
    },
    {
      image: wedl,
      text: (
        <span>
          <h2>Wedl Handelshaus Relaunch</h2>
          <p>
            Der Fokus beim Relaunch lag auf einer kundenorientierten Website,
            die sich durch emotionale Bildsprache präsentiert.
          </p>
        </span>
      ),
      link: '/projekte/wedl'
    },
    {
      image: eglo,
      text: (
        <span>
          <h2>EGLO PIM</h2>
          <p>
            Gemeinsam mit unserem langjährigen Kunden EGLO wurde vor drei Jahren
            ein internes Produktinformationsmanagement (PIM) System für über
            240.000 Produkte ins Leben gerufen.{' '}
          </p>
        </span>
      ),
      link: '/projekte/eglo-pim'
    }
  ]
})

export const frontmatter = {
  title: 'Begeisterung',
  slug: '/Begeisterung'
}

const cards = [
  {
    tag: 'Karriere',
    headline: 'Interview zum Girls Day 2022',
    backgroundImage: anja_thumbnail,
    readingTime: null,
    whiteColor: true,
    link: '/blog/girlsday-2022'
  },
  {
    tag: 'Technologie',
    headline: 'Der Internet Explorer wird eingestellt',
    backgroundImage: ie_grave,
    readingTime: null,
    whiteColor: true,
    link: '/blog/internet-explorer-death'
  },
  {
    tag: 'DSGVO',
    headline: 'matomo statt Google Analytics!',
    subheadline: 'Meiden auch Sie hohe Bußgeldstrafen',
    backgroundImage: null,
    readingTime: null,
    link: '/blog/matomo_statt_google/'
  },
  {
    tag: 'UI - User Interface',
    headline: 'Gradient your life',
    subheadline: 'Farbverläufe und Transitions',
    backgroundImage: Farbverlauf,
    readingTime: null,
    link: '/blog/gradient-your-life'
  },
  {
    tag: 'UX - User Experience',
    headline: 'Micro Interaction',
    subheadline: 'Verbesserung der User Experience',
    backgroundImage: Mouse,
    readingTime: null,
    link: '/blog/micro-interaction'
  },
  {
    tag: 'UI - User Interface',
    headline: 'Fun mit 3d Transitions',
    subheadline: 'Skeomorphe Visuals mit 3D Effekt',
    backgroundImage: null,
    readingTime: null,
    link: '/blog/3d-transitions'
  },
  {
    tag: 'UI - User Interface',
    headline: 'Mobiles Menü ganz unten',
    subheadline: 'Daumen brauchen neue Menüführung',
    backgroundImage: HandyNav,
    readingTime: null,
    whiteColor: true,
    link: '/blog/mobilenavigationganzunten/'
  },
  {
    tag: 'UI - User Interface',
    headline: 'Bild-befüllter Text',
    subheadline: 'Stylish. Punkt.',
    backgroundImage: SandsofTime,
    readingTime: null,
    link: '/blog/bild-befuellter-text'
  },
  {
    tag: 'UI - User Interface',
    headline: 'Split Screens',
    subheadline: 'Dualität mit Style Faktor',
    backgroundImage: HowIsYourDay,
    readingTime: null,
    whiteColor: true,
    link: '/blog/split-screens'
  },
  {
    tag: 'Projektmanagement',
    headline: 'Ticketsystem',
    subheadline: 'Strukturierte Kommunikation während des Projektes',
    backgroundImage: null,
    readingTime: 2,
    blackColor: true,
    link: '/blog/customer-portal/'
  }
]

const Begeisterung = () => (
  <Layout>
    <Helmet
      title="UX Webdesign und Usability bei holzweg GmbH®"
      meta={[
        {
          name: 'description',
          content:
            'Wir liefern UX Webdesign ✓ und Oberflächen für Ihre Software-Lösung ✓ passend für alle Endgeräte, egal ob Desktop, Tablets oder Smartphones. Dabei legen wir großen Wert auf Usability. '
        },
        {
          name: 'keywords',
          content: 'UX Webdesign'
        },
        {
          property: 'og:description',
          content:
            'Wir liefern UX Webdesign ✓ und Oberflächen für Ihre Software-Lösung ✓ passend für alle Endgeräte, egal ob Desktop, Tablets oder Smartphones. Dabei legen wir großen Wert auf Usability. '
        },
        {
          property: 'og:image',
          content: 'https://www.holzweg.com/static/begeisterung.02-fa633e7e7a68de3a73d173e46859e71a.jpg'
        }
      ]}
    ></Helmet>
    <BannerSmall image={BannerImage} title="WebDesign" text="Kreatives Webdesign aus Innsbruck." />
    <TextBox
      title="Professionelles Webdesign für Ihren digitalen Erfolg!"
      text={
        <span>
          <p>Mit unserem umfassenden Know-how und unserer Erfahrung bieten wir Ihnen in unserer Innsbrucker Webagentur erstklassiges Webdesign auf höchstem Niveau. Wir legen großen Wert auf moderne Designtrends sowie eine optimale User Experience (UX) und ein ansprechendes User Interface (UI). Diese Faktoren sind entscheidend für den Erfolg einer Webseite.
          </p>
          <p>
            WebDesign ist heute für Unternehmen von entscheidender Bedeutung. Eine professionelle Webseite kann maßgeblich zum Erfolg des Unternehmens beitragen, indem sie Glaubwürdigkeit und Vertrauen bei potenziellen Kund:innen vermittelt und das Markenimage stärkt. Eine benutzerfreundliche Webseite mit ansprechendem Design und klarer Struktur erleichtert Kund:innen die Kontaktaufnahme und schnelle Informationsbeschaffung. Eine veraltete oder schlecht gestaltete Webseite kann jedoch potenzielle Kund:innen abschrecken und das Interesse an einem Unternehmen mindern.
          </p>
          <p>Unser individuell abgestimmtes Design berücksichtigt Ihre spezifischen Anforderungen und Wünsche. Als erfahrene Webdesigner und Entwickler setzen wir Ihre Vorstellungen in ein modernes und ansprechendes Design um.
          </p>
          <p>Neben Web Entwicklung bieten wir auch Individualsoftware und Custom ERP Systeme an, um Ihre Arbeitsprozesse zu digitalisieren und zu optimieren. Unsere maßgeschneiderten Lösungen werden genau auf Ihre Bedürfnisse zugeschnitten.
          </p>
          <p>Unser Ziel ist es, Ihre digitale Präsenz zu optimieren und Sie in den Suchergebnissen ganz nach oben zu bringen. Kontaktieren Sie uns, um mehr über unser WebDesign und unsere maßgeschneiderten Lösungen zu erfahren.
          </p>
        </span>
      }
    />

    <UserExperience />
    <BlogSlider
      cards={cards}
      singleRow={false}
      title={
        <span>
          Was uns <nobr>gerade</nobr> inspiriert:
        </span>
      }
    />
    <TextBox
      title="Individuell und anpassungs&#173;fähig!"
      text="Durch die Erfahrung mit zahlreichen Kund:innen und Projekten wissen wir, wie wichtig es ist, dass eine Webseite oder App eine individuelle UX und UI bietet. Eine gute User Experience (UX) und User Interface (UI) sorgen dafür, dass Nutzerinnen und Nutzer sich auf der Webseite oder App zurechtfinden und gerne wiederkommen. Individuelle UX und UI setzen sich von der Konkurrenz ab, verbessern die Markenwahrnehmung und erhöhen die Conversion-Rate. Daher legen wir großen Wert auf die Berücksichtigung der Bedürfnisse und Erwartungen unserer Kund:innen und deren Zielgruppe, um eine einzigartige und erfolgreiche UX/UI-Strategie zu entwickeln."
    />
    <AppointmentIndividual
      image={cube} />
    <TextBox
      title={
        <span>
          You have the <nobr>potential</nobr> to make <nobr>beautiful</nobr>{' '}
          things!
        </span>
      }
      text="Als seit 1996 tätige Webagentur aus Innsbruck wissen wir, dass jedes Projekt das Potential hat, etwas Schönes zu werden. Wir setzen uns dafür ein, dass Webdesign, UX und UI perfekt aufeinander abgestimmt sind, um eine großartige Nutzererfahrung zu schaffen und das Potenzial eines jeden Projekts voll auszuschöpfen. Lassen Sie sich selbst von unseren Projekten überzeugen:"
    />
    <ProjectSlider projects={projects} />
    <CallUs contacts={contacts} title="Ansprechpartner gefunden:" />
    <TextBox
      title="Walk with us on the holzweg"
      text="Bereit für eine erfolgreiche Zusammenarbeit mit einer führenden Webagentur in Innsbruck? Kontaktieren Sie uns jetzt für ein unverbindliches Beratungsgespräch oder nutzen Sie unsere Call-Back Funktion. Lassen Sie uns Ihnen zeigen, wie wir Ihr Unternehmen mit unserer jahrelangen Erfahrung in der Gestaltung und Entwicklung von individuellen Websites und Custom ERP-Systemen zum Erfolg führen können. Wenn der “holzweg” nach Ihrem Traumjob klingt, werfen Sie gerne einen Blick auf unsere Stellenangebote oder besuchen Sie uns auf unseren Social-Media-Kanälen."
    />
    <BannerLinks boxes={boxes} />
  </Layout>
)

export default Begeisterung
